/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { LanguageSelector, CustomTextInput, CustomSelect, CountrySelector } from '../../../components/ReduxFormFields';
import { AvatarPreviewer, NavProfileSettings } from '../components';

import { required, minLength, maxLength } from '../../validation';
import { STATUS_LIST, SETTING_PROFILE_FORM } from '../constants';
import { settingPrClearValues } from '../slice';

import i18n from '../../../i18n';

import '../../../App.css';

const valueSelector = formValueSelector(SETTING_PROFILE_FORM);

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

/**
 * Edit profile of user with roleType = 'sub_user'
 * FormSubUser
 * @memberof module:SettingProfile
 */
const FormSubUser = (props) => {
  const {
    handleSubmit,
    openChangeEmail,
    openChangePassword,
    sendEmailRequest,
    myRoleType,
    userRoleName,
    myself,
    pristine,
    submitting,
    initialValues,
    initialize
  } = props;
  const dispatch = useDispatch();

  const first_name = useSelector((state) => valueSelector(state, 'first_name'));
  const last_name = useSelector((state) => valueSelector(state, 'last_name'));

  useEffect(() => {
    initialize({ ...initialValues });

    return () => {
      if (myRoleType !== 'end_user') {
        dispatch(settingPrClearValues());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateStatus = (value) => ['active', 'deactivated'].includes(value);
  const validateValue = ({ value }) => validateStatus(value);

  const hasServiceRights = ['root', 'solar_admin', 'property_management', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only'].includes(myRoleType);
  const hasSupportRights = ['root', 'solar_admin', 'property_management'].includes(myRoleType);
  const roleDependencies = { disabled: !myself && ['viewer', 'pv_installer_employee_read_only', 'oem_employee_read_only'].includes(myRoleType) };

  return (
    <form onSubmit={handleSubmit}>
      <NavProfileSettings
        myself={myself}
        pristine={pristine}
        submitting={submitting}
        handleSubmit={handleSubmit}
        userRoleName={userRoleName}
        roleDependencies={roleDependencies}
      />
      <div className="container-fluid">
        <div className="row align-items-md-start justify-content-md-start justify-content-center settings-user-details">
          <div className="col-md-3 col-auto sub-user-photo">
            <Field
              name="avatar"
              type="file"
              userName={`${first_name} ${last_name}`}
              avatar={initialValues?.avatarURL}
              component={AvatarPreviewer}
              {...roleDependencies}
            />
          </div>
          <div className="row justify-content-around flex-grow-1 flex-shrink-1">
            <div className="col-lg-5">
              <div className="form-group m-form__group input-field">
                <Field
                  name="first_name"
                  component={CustomTextInput}
                  label={i18n.t('reqFirstName')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, minLen3, maxLen50]}
                  {...roleDependencies}
                />
              </div>
              <div className="form-group m-form__group input-field">
                <Field
                  name="last_name"
                  component={CustomTextInput}
                  label={i18n.t('reqLastName')}
                  className="m-input"
                  autoComplete="off"
                  validate={[required, minLen3, maxLen50]}
                  {...roleDependencies}
                />
              </div>
              {hasServiceRights && (
                <div className="form-group m-form__group input-field">
                  <div className="flags-select-label">
                    {i18n.t('status')}
                  </div>
                  <Field
                    name="status"
                    component={CustomSelect}
                    placeholder={i18n.t('selectStatus')}
                    options={STATUS_LIST}
                    isSearchable={false}
                    validate={[required]}
                    filterOption={validateValue}
                    {...roleDependencies}
                    disabled={!hasSupportRights}
                  />
                </div>
              )}
            </div>
            <div className="col-lg-5">
              <div className="form-group m-form__group input-field input-field-for-ch-pass field-color">
                <Field
                  name="old_email"
                  disabled
                  component={CustomTextInput}
                  label={i18n.t('mail')}
                  className="m-input"
                />
                {!roleDependencies.disabled && (
                  <button
                    type="button"
                    onClick={sendEmailRequest}
                    className="resend-btn"
                  >
                    {i18n.t('resendEmail')}
                  </button>
                )}
                <button
                  type="button"
                  onClick={openChangeEmail}
                  className="change-btn"
                >
                  {i18n.t('changeMail')}
                </button>
              </div>
              {!['root', 'solar_admin', 'pv_installer', 'pv_installer_employee'].includes(myRoleType) ? (
                <div className="form-group m-form__group input-field input-field-for-ch-pass">
                  <Field
                    name="password"
                    type="password"
                    disabled
                    component={CustomTextInput}
                    label={i18n.t('pass')}
                    className="m-input"
                    input={{ value: 'password' }}
                  />
                  <button
                    type="button"
                    onClick={openChangePassword}
                    className="change-btn"
                  >
                    {i18n.t('changePass')}
                  </button>
                </div>
              ) : null}
              <div className="form-group m-form__group input-field input-filed-flags-select">
                <div className="flags-select-label">
                  {i18n.t('Country')}
                </div>
                <Field
                  name="country"
                  id="country"
                  component={CountrySelector}
                  buttonClassName="country-btn"
                  label={i18n.t('Country')}
                  className="m-input flagSelectClosed"
                  {...roleDependencies}
                  validate={[required]}
                />
              </div>
              <div className="form-group m-form__group input-field input-filed-flags-select">
                <div className="flags-select-label">
                  {i18n.t('reqLanguage')}
                </div>
                <Field
                  name="language"
                  component={LanguageSelector}
                  buttonClassName="reqLanguage-btn"
                  id="reqLanguage"
                  label={i18n.t('reqLanguage')}
                  className="m-input flagSelectClosed"
                  {...roleDependencies}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

FormSubUser.propTypes = {
  initialize: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openChangeEmail: PropTypes.func,
  openChangePassword: PropTypes.func,
  sendEmailRequest: PropTypes.func,
  myRoleType: PropTypes.string,
  userRoleName: PropTypes.string,
  myself: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: SETTING_PROFILE_FORM
})(FormSubUser);

export default form;
