import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import { isEqual } from 'lodash';
import { createStructuredSelector } from 'reselect';

import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/slice';
import {
  addChoosedCompany,
  monitoringReplaceSelected,
  employeeSendRequest,
  saveAllDataListUsers,
  setMonitoringFilters
} from './slice';
import { EDIT_NOTE_MODAL_ID, modalWindowSend } from '../ModalWindow/slice';
import EditNoteWindow from './components/EditNoteWindow';
import { TopBar, Pagination } from '../../components';
import Changer from './components/Changer';
import { MainMonitoringFilterV2 } from './components/MainMonitoringFilterV2';
import ExpandedRow from './components/ExpandedRow';
import { DataListPagination, DataListV2 } from '../DataList';
import columns from './columns';
import { ERROR_MONITORING_TICKETS_URL, ERROR_MONITORING_TICKET_URL, USERS_EMPLOYEE_URL } from '../../api/apiUrls';
import { DATA_LIST_ID, EMPLOYEE_LIST_ID } from './constants';
import SemiTabs from './components/SemiTabs';
import { companyUserIdSelector, visibleWebSelector } from './selectors';
import { getSignInUserOem_IdSelector } from '../../redux-store/selectors/signIn';
import { getDataListMonitoringDataSelector } from '../../redux-store/selectors/dataList';
import {
  getMonitoringDataSelector,
  getMonitoringEmployeesSelector,
  getMonitoringSelectedRowsSelector
} from '../../redux-store/selectors/monitoring';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';
import { parseChoosedElement, parseStatusOfTicket, transformResponse } from './utils';
import Breadcrumbs from '../../components/Breadcrumbs';
import { ChangeColumnsModalWindow } from '../../modals';

import './index.scss';

const CheckboxTable = checkboxHOC(ReactTable);

const SubComponentWrapper = (rowData) => <ExpandedRow rowData={rowData} />;

/**
 * Renders Monitoring page
 * @memberof module:Monitoring
 */
class Monitoring extends Component {
  constructor(props) {
    super(props);
    const { myRoleType, pagesSettings } = this.props;

    this.state = {
      selectAll: false,
      columns: columns(myRoleType, pagesSettings[DATA_LIST_ID])
    };
    this.areAllSelected = true;
    this.defaultParams = pagesSettings[DATA_LIST_ID].queries;
  }

  componentDidMount() {
    const {
      setMonitoringFilters: setMonitoringFilterFields
    } = this.props;

    /**
     * Load saved filters from local storage
     */
    const monitoringFilter = JSON.parse(localStorage.getItem('monitoringFilter'))?.selectedFields || {};

    setMonitoringFilterFields({ fields: monitoringFilter });
  }

  componentDidUpdate(prevProps) {
    const {
      saveAllDataListUsers: saveAllDataListUsersProps,
      allDataOnPage = [],
      allData = [],
      myRoleType,
      pagesSettings: { [DATA_LIST_ID]: columnsNextSettings }
    } = this.props;
    const {
      allDataOnPage: prevAllDataOnPage,
      pagesSettings: { [DATA_LIST_ID]: columnsPrevSettings }
    } = prevProps;

    if (allDataOnPage && prevAllDataOnPage && !isEqual(prevAllDataOnPage, allDataOnPage)) {
      const newArr = allDataOnPage.filter(({ _id }) => !allData.find(({ _id: idArr1 }) => _id === idArr1));
      saveAllDataListUsersProps({ dataListUsers: newArr });
    }

    const isChangeInstallationFinishedOption = columnsNextSettings.queries.isInstallationCompleted !== columnsPrevSettings.queries.isInstallationCompleted;

    if (!isEqual(columnsPrevSettings.columns, columnsNextSettings.columns || isChangeInstallationFinishedOption)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ columns: columns(myRoleType, columnsNextSettings) });
    }

    this.areAllSelected = true;
  }

  /**
   * Form and send request to get all installers of choosed company
   * @param {Array} arrOfChoosedSides array with all choosed rows with checkbox
   */
  formAndSendRequest = (arrOfChoosedSides) => {
    const {
      allData,
      choosedEmployees = {},
      employeeSendRequest: employeeSendRequestProps,
      addChoosedCompany: addChoosedCompanyProps
    } = this.props;

    const listURL = USERS_EMPLOYEE_URL;
    const userIdArray = parseChoosedElement(allData, arrOfChoosedSides, 'user');
    const companyIdArray = parseChoosedElement(allData, arrOfChoosedSides, 'company');
    const companyStatusArray = parseStatusOfTicket(allData, arrOfChoosedSides, 'status');

    addChoosedCompanyProps({ choosedCompany: companyIdArray, choosedUser: userIdArray, choosedStatus: companyStatusArray });

    const params = {
      _limit: 50,
      _start: 0,
      _sort: 'first_name:asc',
      company: companyIdArray[0]
    };

    if (Array.from(companyIdArray).length === 1 && !companyIdArray.includes('no company')) {
      const allEmployeesOfCheckUser = Object.entries(choosedEmployees).reduce((val, el) => (el[0] === companyIdArray[0] ? el[1].data.list : val), '');
      if (!allEmployeesOfCheckUser || (allEmployeesOfCheckUser && allEmployeesOfCheckUser.length === 0)) {
        employeeSendRequestProps(
          {
            companyID: companyIdArray[0],
            listID: EMPLOYEE_LIST_ID,
            dataList: { listURL, params, transformResponse },
            field: 'employees'
          });
      }
    }
  };

  /**
   * Toggles selection for a row
   * @param {number} key_ ID of row
   */
  toggleSelection = (key_) => {
    const key = key_.replace('select-', '');
    const {
      selectedRows,
      monitoringReplaceSelected: monitoringReplaceSelectedProps
    } = this.props;
    let selection = [...selectedRows];
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
      if (this.areAllSelected) this.areAllSelected = false;
    } else {
      selection.push(key);
      if (!this.areAllSelected) this.areAllSelected = true;
    }

    this.formAndSendRequest(selection);
    monitoringReplaceSelectedProps({ newSelected: selection });
  };

  /**
   * Toggles selection for all rows
   */
  toggleAll = () => {
    const {
      selectedRows,
      monitoringReplaceSelected: monitoringReplaceSelectedProps
    } = this.props;
    const { selectAll } = this.state;
    const areAllSelected = !selectAll;
    let selection = [...selectedRows];

    const wrappedInstance = this.checkboxTable.getWrappedInstance();
    const currentRecords = wrappedInstance.getResolvedState().sortedData;

    if (areAllSelected) {
      // eslint-disable-next-line no-restricted-syntax
      for (const record of currentRecords) {
        if (!selection.includes(record._original._id)) {
          selection.push(record._original._id);
        }
      }
    } else {
      const recorsdsId = currentRecords.map((record) => record._original._id);
      selection = selection.filter((select) => !recorsdsId.includes(select));
    }

    this.formAndSendRequest(selection);
    monitoringReplaceSelectedProps({ newSelected: selection });
    this.setState({ selectAll: areAllSelected });
  };

  saveExpanderChanges = (all) => {
    this.setState({ expanded: all });
  };

  /**
   * Checks if row is selected
   * @returns {boolean}
   */
  isSelected = (key) => {
    const { selectedRows } = this.props;
    return selectedRows.includes(key);
  };

  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { expanded, selectAll } = this.state;
    const {
      myRoleType,
      modalWindowSend: sendModal,
      visibleWeb
    } = this.props;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTdProps: () => ({
        className: 'checkbox-react-table'
      }),
      getTrProps: (allComponentProps, currentProps) => {
        if (!allComponentProps || !currentProps) return {};
        const { selectAll: selectedAll } = this.state;
        const selected = this.isSelected(currentProps.original._id);
        if (this.areAllSelected && !selected) {
          this.areAllSelected = false;
        }

        if (allComponentProps.data.length - 1 === currentProps.index) {
          // if it's the last row
          if (this.areAllSelected && !selectedAll) {
            this.setState({ selectAll: true });
          } else if (!this.areAllSelected && selectedAll) {
            this.setState({ selectAll: false });
          }
        }

        return {
          style: {
            backgroundColor: selected ? '#e8e8e8' : 'inherit'
          }
        };
      },
      getTheadThProps: () => ({
        className: 'checkbox-react-table-all'
      }),
      SelectInputComponent: myRoleType === 'property_management' ? () => null : undefined,
      SelectAllInputComponent: myRoleType === 'property_management' ? () => null : undefined
    };

    const sendSubmitChange = ({ ticketId, noteText }) => sendModal({
      modalID: EDIT_NOTE_MODAL_ID,
      requestConfig: {
        method: 'put',
        url: `${ERROR_MONITORING_TICKET_URL}/${ticketId}`,
        data: {
          note: noteText || ''
        }
      },
      cb: () => dataListReloadData({ listID: DATA_LIST_ID })
    });

    const accepRolesForNotifications = ['pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'pv_installer', 'oem', 'oem_employee', 'oem_employee_read_only'];

    return (
      <div className="main-table-container">
        <EditNoteWindow handleOnSubmit={sendSubmitChange} />
        <ChangeColumnsModalWindow dataListID={DATA_LIST_ID} />

        <TopBar updateButton showNavigation visibleWeb={visibleWeb} />

        <div className="container-fluid">
          <Breadcrumbs crumbs={[{ name: i18n.t('monitoring') }]} />

          {accepRolesForNotifications.includes(myRoleType) && (
            <div className="nav-monitoring">
              <SemiTabs />
            </div>
          )}
        </div>
        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30 px-0">
          <div className="col-xl-12 monitoring-top-bar px-0">
            <Changer />
            <MainMonitoringFilterV2 params={this.defaultParams} />
          </div>
        </div>
        <div className="container-fluid mx-0">
          <div className="mx-0 monitoring-table table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
            <DataListV2
              listID={DATA_LIST_ID}
              listURL={ERROR_MONITORING_TICKETS_URL}
              params={this.defaultParams}
              transformResponse={transformResponse}
              Component={CheckboxTable}
              componentProps={{
                defaultPageSize: 0,
                className: 'monitoring-highlight m-datatable__table',
                columns: this.state.columns,
                SubComponent: SubComponentWrapper,
                manual: true,
                ref: (r) => {
                  this.checkboxTable = r;
                  return r;
                },
                ...checkboxProps,
                onExpandedChange: (all) => {
                  this.saveExpanderChanges(all);
                },
                expanded
              }}
            />
            <DataListPagination
              className="monitoring-table-pagination"
              Component={Pagination}
              listID={DATA_LIST_ID}
              defaultPageSize={10}
              componentProps={{
                savePaginationChanges: this.savePaginationChanges
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

Monitoring.propTypes = {
  modalWindowSend: PropTypes.func.isRequired,
  employeeSendRequest: PropTypes.func.isRequired,
  addChoosedCompany: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  saveAllDataListUsers: PropTypes.func.isRequired,
  allDataOnPage: PropTypes.instanceOf(Array),
  allData: PropTypes.instanceOf(Array),
  monitoringReplaceSelected: PropTypes.func.isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  choosedEmployees: PropTypes.instanceOf(Object),
  visibleWeb: PropTypes.bool,
  setMonitoringFilters: PropTypes.func.isRequired,
  pagesSettings: PropTypes.instanceOf(Object).isRequired
};

const mapStateToProps = createStructuredSelector({
  companyUserId: companyUserIdSelector,
  selectedRows: getMonitoringSelectedRowsSelector,
  allDataOnPage: getDataListMonitoringDataSelector,
  allData: getMonitoringDataSelector,
  choosedEmployees: getMonitoringEmployeesSelector,
  visibleWeb: visibleWebSelector,
  oemId: getSignInUserOem_IdSelector,
  pagesSettings: getPagesSettingsSelector
});

export default connect(
  mapStateToProps,
  {
    addChoosedCompany,
    saveAllDataListUsers,
    monitoringReplaceSelected,
    modalWindowSend,
    employeeSendRequest,
    setMonitoringFilters
  }
)(Monitoring);
